/*@import '~antd/dist/antd.dark.css';*/
.fullHeight{
    height: 100vh;
}

.centerBox{
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: normal;
    flex-wrap: wrap;
    box-sizing: border-box;
    height: 100%;
    display: flex;
}
.login-form {
    max-width: 402px;
    width: -webkit-fill-available;
}
@media only screen and (max-width: 425px){
    .login-form{
        padding: 0 15px;
    }
}
@media only screen and (min-width: 993px){
    .login-form {
        max-width: 402px;
        width: -webkit-fill-available;
    }
}

.ant-input-affix-wrapper{
    padding: 0 15px;
    border-radius: 6px;
    border: 1px solid #ebeff5;
}
.ant-input-affix-wrapper .ant-input-prefix{
    color: #758191;
    font-size: 20px;
}
.ant-input-affix-wrapper .ant-input{
    font-size: 14px;
    line-height: 18px;
    padding: 16px 16px 16px 50px;
    color: #1d2329;
    max-width: none;
    height: 50px;
    font-family: 'Fira Sans',sans-serif;
    transition: color .2s,background-color .2s,border-color .2s;
    margin-left: 10px;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
    box-shadow: 0 8px 26px 0 rgba(34,60,169,.31);
    font-size: 14px;
    height: 50px;
    border-radius: 5px;
}

.login-form .text-center{
    font-size: 12px;
    transition: color .2s;
}

.login-form .facebook-btn{
    position: relative;
    display: block;
    border: 1px solid #ebeff5;
    border-radius: 6px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    outline: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #1d2329;
    font-weight: 500;
    transition: color .2s,border-color .2s;
}
.login-form .facebook-btn:hover{
    border-color: #c9cdd5;
}
.login-form .facebook-btn:active{
    border-color: #a4a8b0;
}

.logo{
    margin: 50px 0;
    /*transform: translate(-50%, 0px);
    left: 50%;*/
}

.bk-color{
    background-color: #bfd8ef;
}
.full-width-right-pt{
    position: fixed;
    right: 0px;
    width: 50%;
    top: 0px;
    height: 100%;
}

.image-pt2{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: relative;
    max-width: 427px;
}
@media only screen and (max-width: 991.5px){
    .second-box{
        display: none;
    }
}
@media only screen and (min-width: 1280px){
    .image-pt2{
        max-width: 567px;
    }
}

@media only screen and (min-width: 1440px){
    .image-pt2{
        max-width: 596px;
    }
}
