textarea:where(.css-dev-only-do-not-override-1km3mtt).ant-input {
    border-radius: 6px !important;
}
textarea:where(.css-dev-only-do-not-override-1km3mtt).ant-input:focus{
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
textarea:where(.css-dev-only-do-not-override-1km3mtt).ant-input:hover{
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity));
}