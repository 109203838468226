
body {
    overflow-x: hidden;
    display: initial;
}
@media only screen and (min-width: 767px){
    body {
        overflow-x: auto !important;
        overflow: hidden !important;
    }
}


.site-page-header{
    position: relative;
    z-index: 9999;
}
.content-project-items *{
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */  
}
.content-project-items:hover .project-item{
    opacity: .5;
}
.project-item{
    transition: transform .2s,opacity .2s,-webkit-transform .2s;
    display: block;
    margin-bottom: 20px;
}
.project-item:hover {
    transform: scale(1.15);
    opacity: 1 !important;
}
.project-item:hover .avatar-project{
    border-radius: 100%;
}
.project-item:hover .projects__item-title{
    color: #fff;
}
.avatar-project{
    width: 90px;
    height: 90px;
    border-radius: 40%;
    transition: border-radius .3s;
}

.projects__item-title {
    font-size: 16px;
    color: #b6c0ce;
    line-height: 1.22;
    padding: 0 10px;
    margin-top: 18px;
    transition: color .2s;
    margin-left: 20px;
}
.add-project{
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 18px;
    background: transparent;
    color: #fff;
    border: 1px solid rgb(255 255 255 / 0.5);
}


.gooey{
    background-image: linear-gradient(120deg, #2479fe 0%, #2060fe 100%);
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: 1124px;
    height: 1124px;
    animation: morph 10s linear infinite;
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
    position: absolute;
    right: -280px;
    top: -200px;
    opacity: .8;
    z-index: -1;
}
.gooey:before,
.gooey:after{
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    /*box-shadow: 5px 5px 89px rgb(0 102 255 / 23%);*/
    will-change: border-radius, transform, opacity;
    animation-delay: 200ms;
    background-image: linear-gradient(120deg, rgb(0 67 255) 0%, rgb(0 103 255) 100%);
}

.gooey:before{
    animation: morph 10s linear infinite;
    opacity: 1;
    animation-duration: 5s;
}

.gooey:after{
    animation: morph 10s linear infinite;
    animation-delay: 400ms;
    opacity: 1;
    content: "";
    line-height: 120px;
    text-indent: -21px;
}

@keyframes morph{
    0%,100%{
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    34%{
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
    }
    50%{
    transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
    }
}


@media only screen and (max-width: 1380px){
    .gooey {
        right: -480px;
    }
}

@media only screen and (max-width: 1070px){
    .gooey {
        right: -620px;
    }
}


@media only screen and (max-width: 767px){
    .centerBox{
        height: 300px;
    }
    .gooey {
        right: 0px;
        width: 500px;
        height: 500px;
        bottom: 0;
        top: unset;
        display: none;
    }
    .ant-page-header-heading-extra button{
        color: rgba(0, 0, 0, 0.85) !important;
    }
}
