@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700;800;900&display=swap');
@import '~antd/dist/reset.css';
@import  'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200';

*{
    margin: 0;
    padding: 0;
}

body, p, h1, h2, h3, h4 ,h5, h6, a, li{
    font-family: 'Fira Sans', sans-serif;
}
.text-center{
    text-align: center;
}
.display-flex{
    display: flex;
}
.ant-tooltip-inner{
    font-size: .8rem;
}

.ant-btn{
    border-radius: 5px !important;
}


.ant-table-cell.ant-table-cell-row-hover .z-10{
  z-index: auto;
}
body{
    animation: fadeIn 500ms reverse;
}


@keyframes fadeIn{
    100%{
    transform: scale(1.03);
    opacity: 0;
    }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' -25,
  'opsz' 48
}
.material-symbols-outlined.bold {
  font-variation-settings:
  'FILL' 0,
  'wght' 700,
  'GRAD' 200,
  'opsz' 48
}

.ant-btn-loading-icon{
  position: relative;
  top: -4px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar::-webkit-scrollbar, body::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  
    .scrollbar::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: /*#f7f4ed;*/ transparent;
    }
  
    .scrollbar::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
      background: rgba(99 102 241 / .2);
      border-radius: 100vh;
      border: 3px solid #f6f7ed;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
      background: rgba(99 102 241 / .3);
    }
}

@layer components {
    .active{
        text-color: theme('colors.text-indigo-500');
    }
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-popconfirm .ant-popconfirm-buttons button.ant-btn-primary{
  background-color: rgb(55 48 163) !important;
}