.collapse_ord{
    margin-left: -5px;
}
.collapse_ord .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon{
    order: 1;
    padding-inline-end: 0px;
    padding-inline-start: 12px;
    
}
.collapse_ord .ant-collapse-item .ant-collapse-header{
    padding: 0px;
    color: rgb(77, 86, 121);
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0;
    display: inline-flex;
    border-radius: 5px !important;
    padding-left: 8px;
    padding-right: 8px;
}
.collapse_ord .ant-collapse-item .ant-collapse-header:hover{
    background-color: rgba(209 213 219 / .2);
}
.collapse_ord .ant-collapse-item .ant-collapse-header:focus{
    background-color: rgb(99 102 241 / .1);
    color: rgb(99 102 241 / 1);
}
.collapse_ord .ant-collapse-item .ant-collapse-content-box{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
}